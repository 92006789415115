<template>
  <RobiPromotion URL="../images/5.png"></RobiPromotion>
</template>

<script>
import RobiPromotion from "./RobiPromotion.vue";

export default {
  name: "RobiPromotionOne",
  components: {
    RobiPromotion
  }
};
</script>

<style></style>
